$amp: '.livechat';

#{$amp} {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-height: 66px;
    border-radius: 8px 8px 0 0;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
    z-index: 15;
    overflow: hidden;
    pointer-events: all;
    background-color: $c-white;

    @media (min-width: $widget-fullscreen-bp) {
        width: $widget-width;
        right: 20px;
        left: auto;
    }

    &--open {
        top: 0;
        max-height: initial;

        @media (min-width: $widget-fullscreen-bp) {
            max-height: $widget-max-height;
            bottom: 0;
            top: auto;
        }

        .box__content {
            height: calc(100% - 132px);
        }

        .publisher {
            display: flex;
        }
    }
}
