$c-white: #ffffff;
$c-black: #000000;

$c-custra     : #F77535;
$c-crimson    : #EC1846;
$c-mineShaft  : #2F2F2F;
$c-mineShaft2 : #2D2D2D;
$c-gray       : #8D8D8D;
$c-alto       : #D8D8D8;
$c-alto2      : #E0E0E0;
$c-seashell   : #F1F0F0;
$c-alabaster  : #FBFBFB;
$c-nobel      : #B7B7B7;
$c-concrete   : #F2F2F2;
$c-doveGray   : #696969;
$c-doveGray2  : #666666;
$c-wildSand   : #F6F5F5;
$c-silver     : #C0C0C0;
