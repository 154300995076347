$amp: '.box';

#{$amp} {
    height: 100%;

    &__header {
        position: relative;
        background: linear-gradient(-90deg, $c-custra 0%, $c-crimson 100%);
        border-radius: 10px 10px 0 0;
        padding: 14px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

        &__title > img {
            height: 38px;
        }

        &__toggle {
            width: 30px;
            height: 30px;
            line-height: 30px;
            position: absolute;
            right: 15px;
            color: $c-white;
            border: 1px solid $c-white;
            text-align: center;
            box-sizing: border-box;
            border-radius: 8px;
        }

    &__content {
        background-color: $c-white;
        overflow: hidden;
        height: 0;
    }

        &__wrapper {
            height: 100%;
            padding: 40px 20px 0 20px;
            position: relative;
            overflow-y: auto;
        }

        &__end {
            margin-top: $media-gutter;
        }

        &__sep {
            font-family: $ff-rb;
            font-weight: $fw-bold;
            font-size: $sep-font-size;
            line-height: $sep-line-height;
            text-align: center;
            color: $sep-color;
            text-transform: capitalize;
            padding: $sep-padding;
        }
}
