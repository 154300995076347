$amp: '.typing';

#{$amp} {
    position: relative;
    display: inline-block;

    &:not(.typing--finished) > * {
        visibility: hidden;
    }

    &__animate {
        position: absolute;
        top: 0;
        left: 0;
        visibility: unset !important;
    }
}
