$amp: '.info';

#{$amp} {
    text-align: center;
    padding: 0 20px;
    margin-bottom: $media-gutter;

    & > img {
        width: 36px;
        margin-bottom: 20px;
    }

    &__title {
        font-family: $ff-rk;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.6px;
        color: $c-mineShaft;
        margin-bottom: 15px;

        & > b {
            font-weight: $fw-medium;
        }
    }

    &__desc {
        font-family: $ff-rb;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: $c-gray;
        margin: 0 auto 30px auto;
    }

    & > hr {
        margin: 0 auto;
        height: 1px;
        background: $c-alto;
        background: linear-gradient(90deg, rgba($c-alto, 0) 0%, $c-alto 50%, rgba($c-alto, 0) 100%);
        border: none;
    }
}
