html {
    -webkit-text-size-adjust: none;
}

.clearfix::after {
    display: block;
    content: "";
    clear: both;
}

html {
    width: 100%;
}

body {
    min-width: 320px;
    @include smooth();
    background-color: #eee;
    min-height: 100vh;
    margin: 0 auto;
    -webkit-tap-highlight-color: transparent;
}

b {
    font-weight: $fw-bold;
}

p {
    margin: 0 0 20px 0;
}

button,
input,
optgroup,
select,
textarea {
    font-family: Roboto, sans-serif;
    font-weight: 300
}

img {
    border-style: none;
    vertical-align: middle;
    max-width: 100%;
}
