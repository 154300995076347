@charset "UTF-8";

/* === Boostrap Main SCSS === */
@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/card";

/*-------------------------------------------------------------------*/

// === Mixins ===
@import "scss/mixins/centering";
@import "scss/mixins/clearfix";
@import "scss/mixins/embedcontainer";
@import "scss/mixins/imagecover";
@import "scss/mixins/imagerenderfix";
@import "scss/mixins/smooth";
@import "scss/mixins/renderfixes";
@import "scss/mixins/button";


// === Functions ===
@import "scss/mixins/functions";

// === Config files ===
@import "scss/config/colors";
@import "scss/config/variables";
@import "scss/config/fonts";
@import "scss/config/base";

// === Components ===
@import "scss/components/livechat";
@import "scss/components/info";
@import "scss/components/media";
@import "scss/components/box";
@import "scss/components/publisher";
@import "scss/components/typing";
@import "scss/components/askback";


// === Pages ===
//@import "layout/preview";


// === Partials ===
//@import 'partials/header';


// === Overwrites ===
//@import 'overwrites/select2';


.media-cards {
    border-radius: 0;
    border-style: none;
    max-width: 100%;
}

.media-cards-wrapper {
    overflow: auto;
    overflow-x: scroll;
}

.media-buttons {
    margin: 1px 0;
    width: 100%;
}

.media-text + .media-buttons {
    margin-top: -8px;
}

.media-buttons > .media-button {
    display: block;
    line-height: 32px;
    color: #0084ff;
    padding: 0 10px;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 4px;
    position: relative;
    width: 100%;
    background-color: #fff;
    text-align: center;
}

.media-buttons > .media-button + .media-button {
    margin-top: 1px;
}
