$amp: '.publisher';

#{$amp} {
    position: relative;
    align-items: center;
    padding: 4px;
    border: 1px solid $c-seashell;
    display: none;

    &__input {
        appearance: none;
        flex-grow: 1;
        outline: none;
        resize: none;
        background: $c-alabaster;
        font-family: $ff-rb;
        font-size: 15px;
        line-height: 15px;
        border-radius: 15px;
        padding: 18px 15px;
        color: $c-mineShaft;
        border: solid 1px $c-wildSand;

        @include placeholder {
            color: $c-nobel;
        }
    }
}
