$amp: '.askback';

#{$amp} {
    width: 100%;
    position: relative;

    &__wrapper {
        width: 100%;
        position: absolute;
        bottom: 70px;
        background: #fbfbfb;
        border: solid 1px #F6F5F5;
        visibility: hidden;
        padding: 10px 10px 5px 10px;
        max-height: 100px;
        overflow-y: auto;

        &.show {
            visibility: visible;
        }
    }

    &__btn {
        cursor: pointer;
        margin: 0 5px 5px 0;
        padding: 5px 10px;
        border: 1px solid $c-alto2;
        background: #fff;
        border-radius: 18px;
        font-size: 14px;
        line-height: 21px;
        color: $c-doveGray;
        display: inline-block;
        text-decoration: none;

        &:not(.inactive):hover,
        &.selected {
            background-color: $c-crimson;
            color: $c-white;
        }

        &.selected,
        &.inactive {
            cursor: default;
        }
    }

    input {
        width: 100%;
    }
}