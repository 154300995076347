$amp: '.media';

#{$amp} {
    position: relative;
    animation: SHOWMEDIA .3s ease-in;
    @include clearfix();

    &__body {
        @include clearfix();

        & > * {
            float: left;
        }
    }

    &__bubble {
        position: relative;
        padding: $media-gutter;
        font-family: $ff-rb;
        font-size: $media-font-size;
        line-height: $media-line-height;
        background: $c-concrete;
        border-radius: $media-border-radius-middle;
        max-width: $media-max-width;
        float: left;
        color: $c-doveGray;
    }

    &__replies {
        width: 100%;

        & > a {
            cursor: pointer;
            margin: 5px;
            padding: 5px 10px;
            border: 1px solid $c-alto2;
            background: #fff;
            border-radius: 18px;
            font-size: 14px;
            line-height: 21px;
            color: $c-doveGray;
            display: inline-block;
            text-decoration: none;

            &:not(.inactive):hover,
            &.selected {
                background-color: $c-crimson;
                color: $c-white;
            }

            &.selected,
            &.inactive {
                cursor: default;
            }
        }
    }

    &__options {
        width: 100%;

        & > #{$amp}__option {
            cursor: pointer;
            margin: 5px;
            padding: 5px 15px;
            border: 1px solid $c-crimson;
            background: #fff;
            border-radius: 18px;
            font-family: $ff-rb;
            font-size: 14px;
            line-height: 21px;
            color: $c-mineShaft;
            display: inline-block;
            text-decoration: none;

            &.inactive {
                border-color: $c-alto2;
                color: $c-silver;
            }

            &.selected {
                font-weight: $fw-bold;
            }

            &:not(.inactive):hover,
            &.selected {
                border-color: transparent;
                color: $c-white;
                background: linear-gradient(-90deg, $c-custra 0%, $c-crimson 120%);
            }

            &.selected,
            &.inactive {
                cursor: default;
            }
        }
    }

    &--user  &__bubble {
        background: linear-gradient(-90deg, $c-custra 0%, $c-crimson 120%);
        color: $c-white;
        float: right;
    }
}

#{$amp}--user + #{$amp}--user,
#{$amp}--bot + #{$amp}--bot {
    margin-top: $media-same-type-margin;
}

.media-group {
    margin-top: $media-margin;

    & > .media--user:first-child .media__bubble {
        border-top-right-radius: $media-border-radius-first;
    }

    & > .media--bot:first-child .media__bubble {
        border-top-left-radius: $media-border-radius-first;
    }


    & > .media--user:last-child .media__bubble {
        border-bottom-right-radius: $media-border-radius-last;
    }

    & > .media--bot:last-child .media__bubble {
        border-bottom-left-radius: $media-border-radius-last;
    }
}

#{$amp}--alt {
    margin-top: 20px !important;
    margin-bottom: 20px;

    .media__bubble {
        border-radius: unset !important;
        background-color: transparent;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.03em;
        padding: 0;
        margin-bottom: 5px;

        &::before {
            content: '❓ ';
        }
    }
}
